import React, { useEffect, useState } from "react"
import {
  FlexBox,
  DescriptionBoxHolder,
  ContainerMobile,
  ImageGroup1,
  DescriptionBox,
  Title,
  Description,
  ExampleImg,
} from "../../../ToolsStyles/HowItWorksStyeled"
import Step1 from "../../Assets/HowToImage_Step_1_extract.png"
import Step2 from "../../Assets/HowToImage_Step_2_extract.png"
import Step3 from "../../Assets/HowToImage_Step_3_extract.png"
import Group1 from "../../../merge/MergeAssets/Group1.svg"
import Group2 from "../../../merge/MergeAssets/Group2.svg"
import Group3 from "../../../merge/MergeAssets/Group3.svg"

const HowItWorksMobile = () => {
  const [IsActive, setIsActive] = useState(1)

  useEffect(() => {
    window.clicked = false
    const intervalId = setInterval(() => {
      if (!window.clicked) {
        setIsActive(prevActive => {
          const newActive = prevActive === 3 ? 1 : prevActive + 1
          return newActive
        })
      } else {
        window.clicked = false
      }
    }, 5000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const ActiveTabOne = e => {
    setIsActive(1)
    window.clicked = true
  }

  const ActiveTabTwo = () => {
    setIsActive(2)
    window.clicked = true
  }

  const ActiveTabThree = () => {
    setIsActive(3)
    window.clicked = true
  }

  return (
    <ContainerMobile>
      <FlexBox>
        <DescriptionBoxHolder>
          <DescriptionBox>
            <ImageGroup1 src={Group1} />

            <Title ActiveState={IsActive === 1}>Upload documents</Title>
            <Description>
              Upload the documents you want to extract pages from. Either by
              using the Upload button or by dragging-and-dropping the PDF you
              want to work on into the online tool.
            </Description>
          </DescriptionBox>
          <ExampleImg src={Step1} alt="example of how to upload file" />
          <DescriptionBox>
            <ImageGroup1 src={Group2} />

            <Title ActiveState={IsActive === 2}>Press Extract Pages</Title>
            <Description>
              Press the Extract Pages button and follow the on-screen prompts to
              specify which page or pages you would like to extract from the
              PDF.
            </Description>
          </DescriptionBox>
          <ExampleImg src={Step2} alt="example of how to extract file" />

          <DescriptionBox>
            <ImageGroup1 src={Group3} />

            <Title ActiveState={IsActive === 3}>Download</Title>
            <Description>
              {" "}
              Once your pages have been extracted, a new PDF file will be
              created containing your extracted pages. Download your new PDF!
            </Description>
          </DescriptionBox>
          <ExampleImg src={Step3} alt="example of how to download file" />
        </DescriptionBoxHolder>
      </FlexBox>
    </ContainerMobile>
  )
}

export default HowItWorksMobile
