import React from "react"
import {
  Container,
  Title,
  Description,
  DescriptionBox,
  SvgHolder,
} from "../../../ToolsStyles/ShortDescriptionStyled"

import Desc1 from "../../../../../icons/tools/extract/ExtractIcon1.svg"
import Desc2 from "../../../../../icons/tools/extract/ExtractNewIcon2.svg"
import Desc3 from "../../../../../icons/tools/lp/merge/DescIcon3.svg"
import Desc4 from "../../../../../icons/tools/lp/merge/DescIcon4.svg"
import Desc5 from "../../../../../icons/tools/lp/merge/DescIcon5.svg"
import Desc6 from "../../../../../icons/tools/lp/merge/DescIcon6.svg"

const ShortDescriptions = () => {
  return (
    <Container>
      <DescriptionBox>
        <SvgHolder>
          <Desc1 />
        </SvgHolder>
        <Title>Easily Extract Pages from PDF</Title>
        <Description>
          Have a specific page that you need from a larger document? Extract the
          exact page you need from any PDF file.
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc2 />
        </SvgHolder>
        <Title>Why Extract Pages from PDF?</Title>
        <Description>
          Organize documents, improve collaboration, keep certain pages private
          by extracting them from your PDF.
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc3 />
        </SvgHolder>
        <Title>Safe & Secure — Your Privacy Matters to Us</Title>
        <Description>Uploaded files are deleted after 24 hours. </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc4 />
        </SvgHolder>
        <Title>100% Free to Use</Title>
        <Description>
          There are no limits on our Extract PDF Pages Tool. Extract as many
          pages from as many documents as you like.
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc5 />
        </SvgHolder>
        <Title>No Installation or Sign-up Required</Title>
        <Description>
          No downloading software or signing up necessary. Simply visit this
          webpage from your browser, and start extracting.
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc6 />
        </SvgHolder>
        <Title>Works on Any Device</Title>
        <Description>
          This online tool works from within your browser, so you can delete PDF
          pages from any device or system, not just your computer.
        </Description>
      </DescriptionBox>
    </Container>
  )
}

export default ShortDescriptions
