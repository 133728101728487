import client from "../toolApi"
import { DataLayer } from "../../helpers/GA"
export const ExtractFiles = (data, setDownload) =>
  client
    .post("/uploadNew", data.formData)
    .then(res => {
      window.fileName = res.data.message[0]
      DataLayer("Online tool", "Upload", "Merge")
      client
        .get(`/extractTool?pages=${data.pages}&fileName=${window.fileName}`)
        .then(res => {
          DataLayer("Online tool", "Merge pdfs", "Merge")
          client
            .get(`/downloadExtract?fileName=${window.fileName}`, {
              responseType: "blob",
            })
            .then(res => {
              console.log("Downloaded: ", res)
              const link = document.createElement("a")
              setDownload(link)
              link.href = URL.createObjectURL(new Blob([res.data]))
              link.download =
                data.fileName.replace(".pdf", "") + "_extracted.pdf"
              document.body.appendChild(link)
              link.click()
              DataLayer("Online tool", "Download", "Merge")
            })
            .catch(() => {
              DataLayer("Online tool", "Error download", "Merge")
            })
        })
        .catch(() => {
          DataLayer("Online tool", "Error merge pdfs", "Merge")
        })
    })
    .catch(() => {
      DataLayer("Online tool", "Error upload", "Merge")
    })
