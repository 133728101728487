import React from "react"
import {
  ChildContainer,
  Description,
  ParentContainer,
  Title,
  Image,
  TitleSub,
  SubText,
  Ul,
  LearnMore,
  SvgHolder,
  TitleHolder,
} from "../../../ToolsStyles/UsefulToolsStyled"
import Merge from "../icons/merge.svg"
import Rotate from "../icons/rotate.svg"

const UsefulTools = () => {
  return (
    <ParentContainer>
      <TitleHolder>
        <Title>Continue to organize this PDF</Title>
        <Description>
          Here are a couple other tools that you may want to use on your current
          PDF document.
        </Description>
        <LearnMore to="/pdf-online-tools/">
          View all Online tools &rarr;
        </LearnMore>
      </TitleHolder>

      <ChildContainer>
        <SvgHolder>
          <Rotate />
        </SvgHolder>
        <TitleSub>Rotate PDF</TitleSub>
        <SubText>Rotate individual PDF pages or entire documents.</SubText>
        <LearnMore to="/rotate-pdf/">Continue &rarr;</LearnMore>
      </ChildContainer>

      <ChildContainer>
        <SvgHolder>
          <Merge />
        </SvgHolder>
        <TitleSub>Merge PDF</TitleSub>
        <SubText>Combine two or more PDFs into a single PDF.</SubText>
        <LearnMore to="/merge-pdf/">Continue &rarr;</LearnMore>
      </ChildContainer>
    </ParentContainer>
  )
}

export default UsefulTools
